/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
// import Header from "./header"
import "./layout.css"
import github from "../images/github-octocat.svg"
import gitlab from "../images/gitlab.svg"
import itch from "../images/itch.svg"
import linkedIn from "../images/linkedin.svg" 
import bitbucket from "../images/bitbucket.svg"
import gmail from "../images/gmail.svg"
import npm from "../images/npm.svg"

const FadeIn = ({children}) => {
  const myRef = React.createRef();
  setTimeout(() => {
    myRef.current.style.opacity = 1;
    myRef.current.style.pointerEvents = "auto";
  }, 6000);
  return (
    <div ref={myRef} style={{opacity: 0, transition: "opacity 4s", pointerEvents:"none"}}>{children}</div>
  )
}

const Link = props => <a rel="noopener noreferrer" {...props}>{props.children}</a>

const Layout = ({ children }) => {
  /* const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `) */

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */} 
      <div
        style={{
          margin: `0 auto`,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <FadeIn>
        
          <footer style={{marginBottom:0, position: "absolute", bottom: 0, left: 0, textAlign: "center", width: "100%"}}>
            <span >© Gabe Troyan {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a></span>
            
            
            
          </footer>
          <span style={{position:"absolute", bottom: "20px", right: "2vw"}}>
            <Link style={{paddingLeft: "15px"}} href="mailto:Gabe@Gabe.ws" target="_blank" title="Email me"><img src={gmail} alt="Email Address" width="35px" height="35px" style={{margin:0, paddingBottom: "2.5px"}}/></Link>
            <Link style={{paddingLeft: "10px"}} target="_blank" title="Connect with me on LinkedIn" href="https://LinkedIn.com/in/Gabe"><img src={linkedIn} alt="Link to LinkedIn profile" width="40px" height="40px" style={{margin:0}}/></Link>
            <Link style={{paddingLeft: "10px"}} target="_blank" title="Browse NPM packages in new tab" href="https://www.npmjs.com/~gabeeddyt"><img src={npm} alt="Link to NPM packages" width="35px" height="35px" style={{margin:0, paddingBottom: "5px"}}/></Link>
            <Link style={{paddingLeft: "10px"}} target="_blank" title="View GitHub profile in new tab" href="https://GitHub.com/GabeEddyT"><img src={github} alt="Link to GitHub profile" width="40px" height="40px" style={{margin:0}}/></Link>
            <Link style={{paddingLeft: "10px"}} target="_blank" title="View GitLab profile in new tab" href="https://GitLab.com/GabeEddyT"><img src={gitlab} alt="Link to GitLab profile" width="35px" height="35px" style={{margin:0, paddingBottom: "5px"}}/></Link>
            <Link style={{paddingLeft: "10px"}} target="_blank" title="View Bitbucket profile in new tab" href="https://BitBucket.com/GabeEddyT"><img src={bitbucket} alt="Link to BitBucket profile" width="35px" height="35px" style={{margin:0, paddingBottom: "5px"}}/></Link>
            <Link style={{paddingLeft: "10px"}} target="_blank" title="Play my games on Itch.io" href="https://GabeEddyT.itch.io"><img src={itch} alt="Link to Itch.io page" width="38px" height="38px" style={{margin:0, paddingBottom: "5px"}}/></Link>
          </span>
        </FadeIn>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
